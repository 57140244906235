.JGspecialTestDetail .info_box {
  padding: 10px 20px;
  background-color: #f2f7fd;
  border-radius: 8px;
  margin-bottom: 10px;
}
.JGspecialTestDetail .info_box .title {
  font-size: 16px;
  font-weight: 600;
}
.JGspecialTestDetail .info_box .info {
  padding: 10px 50px;
}
.JGspecialTestDetail .info_box .info > div {
  margin-bottom: 8px;
}
.JGspecialTestDetail .info_box .info > div .label {
  min-width: 80px;
  text-align: right;
}
.JGspecialTestDetail .info_box .info > div:last-child {
  margin-bottom: 0;
}
